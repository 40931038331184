import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../store/redux/userRedux";
import avatar from "../assets/user.png";
import Preloader from "../components/Preloader";
import axios from "axios";

const Transactions = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [removeActiveClass, setRemoveActiveClass] = useState(false);
  const [addBodyClass, setAddBodyClass] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [transactionToView, setTransactionToView] = useState<any>({});

  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("paylio");
    dispatch(logoutUser());
    navigate("/login");
  };

  const toggleSidebar = () => {
    setRemoveActiveClass((prevState) => !prevState);
    setAddBodyClass((prevState) => !prevState);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 1399) {
      setRemoveActiveClass(true);
      setAddBodyClass(true);
    } else {
      setRemoveActiveClass(false);
      setAddBodyClass(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    const token = localStorage.getItem("paylio");

    const getTransactions = async () => {
      const { data } = await axios.get(
        `https://paylio-backend.onrender.com/api/transaction/${user?._id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactions(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getTransactions();
  }, [user?._id]);

  return (
    <>
      {pageLoading && <Preloader />}
      <header
        className={`${
          addBodyClass ? "header-section body-collapse" : "header-section"
        }`}
      >
        <div className="overlay">
          <div className="container-fruid">
            <div className="row d-flex header-area">
              <div className="navbar-area d-flex align-items-center justify-content-between">
                <div className="sidebar-icon" onClick={toggleSidebar}>
                  <img src="/assets/images/icon/menu.png" alt="icon" />
                </div>
                <form action="#" className="flex-fill">
                  <div className="form-group d-flex align-items-center">
                    <img src="/assets/images/icon/search.png" alt="icon" />
                    <input type="text" placeholder="Type to search..." />
                  </div>
                </form>
                <div className="dashboard-nav">
                  <div className="single-item user-area">
                    <div className="profile-area d-flex align-items-center">
                      <span className="user-profile">
                        {user?.picture ? (
                          <img
                            src={user?.picture}
                            alt="User"
                            style={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src={avatar}
                            alt="User"
                            style={{
                              width: "32px",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </span>
                      <i className="fa-solid fa-sort-down" />
                    </div>
                    <div className="main-area user-content">
                      <div className="head-area d-flex align-items-center">
                        <div className="profile-img">
                          {user?.picture ? (
                            <img
                              src={user?.picture}
                              alt="User"
                              style={{
                                width: "60px",
                                height: "60px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          ) : (
                            <img
                              src={avatar}
                              alt="User"
                              style={{
                                width: "60px",
                                borderRadius: "50%",
                              }}
                            />
                          )}
                        </div>
                        <div className="profile-head">
                          <a href="#/">
                            <h5>
                              {user?.firstName} {user?.middleName}{" "}
                              {user?.lastName}
                            </h5>
                          </a>
                          <p className="wallet-id">
                            Account ID: {user.accountNumber}
                          </p>
                        </div>
                      </div>
                      <ul>
                        <li className="border-area">
                          <Link to="/account">
                            <i className="fas fa-cog" />
                            Settings
                          </Link>
                        </li>
                        <li>
                          <a href="#/" onClick={logout}>
                            <i className="fas fa-sign-out" />
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  removeActiveClass
                    ? "sidebar-wrapper"
                    : "sidebar-wrapper active"
                }`}
              >
                <div className="close-btn" onClick={toggleSidebar}>
                  <i className="fa-solid fa-xmark" />
                </div>
                <div className="sidebar-logo">
                  <Link to="/">
                    <img src="assets/images/logo.png" alt="logo" />
                  </Link>
                </div>
                <ul>
                  <li>
                    <Link to="/">
                      <img
                        src="assets/images/icon/dashboard.png"
                        alt="Dashboard"
                      />{" "}
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li className="active">
                    <Link to="/transactions">
                      <img
                        src="assets/images/icon/transactions.png"
                        alt="Transactions"
                      />
                      <span>Transactions</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/deposit">
                      <img src="assets/images/icon/deposit.png" alt="Deposit" />{" "}
                      <span>Deposit Money</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/transfer">
                      <img
                        src="assets/images/icon/exchange.png"
                        alt="Exchange"
                      />{" "}
                      <span>Transfer</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/account">
                      <img src="assets/images/icon/account.png" alt="Account" />{" "}
                      <span>Account</span>
                    </Link>
                  </li>
                  <li>
                    <a href="#/" onClick={logout}>
                      <img src="assets/images/icon/quit.png" alt="Quit" />{" "}
                      <span>Quit</span>
                    </a>
                  </li>
                </ul>
                <div className="pt-120">
                  <div className="invite-now">
                    <div className="img-area">
                      <img
                        src="assets/images/invite-now-illus.png"
                        alt="dashboard"
                      />
                    </div>
                    <p>Invite your friend and get $25</p>
                    <a href="#/" className="cmn-btn">
                      Invite Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="dashboard-section body-collapse transactions">
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="head-area">
              <div className="row">
                <div className="col-lg-5 col-md-4">
                  <h4>Transactions</h4>
                </div>
                {/* <div className="col-lg-7 col-md-8">
                  <div className="transactions-right d-flex align-items-center">
                    <form action="#" className="flex-fill">
                      <div className="form-group d-flex align-items-center">
                        <img src="assets/images/icon/search.png" alt="icon" />
                        <input type="text" placeholder="Type to search..." />
                      </div>
                    </form>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="transactions-main">
                  <div className="top-items">
                    <h6>All Transactions</h6>
                    {/* <div className="export-area">
                      <ul className="d-flex align-items-center">
                        <li>
                          <a href="#/">
                            <img
                              src="assets/images/icon/printer.png"
                              alt="icon"
                            />
                            Print
                          </a>
                        </li>
                        <li>
                          <a href="#/">
                            <img
                              src="assets/images/icon/excel.png"
                              alt="icon"
                            />
                            Excel
                          </a>
                        </li>
                        <li>
                          <a href="#/">
                            <img src="assets/images/icon/pdf.png" alt="icon" />
                            PDF
                          </a>
                        </li>
                        <li>
                          <a href="#/">
                            <img src="assets/images/icon/csv.png" alt="icon" />
                            CSV
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  {/* <div className="filters-item">
                    <div className="single-item">
                      <select>
                        <option value={1}>23 Nov 2021- 21 Feb 2022</option>
                        <option value={2}>23 Feb 2021- 21 Mar 2022</option>
                        <option value={3}>23 Mar 2021- 21 Apr 2022</option>
                      </select>
                    </div>
                    <div className="single-item">
                      <select>
                        <option value={1}>Balance</option>
                        <option value={2}>Balance</option>
                        <option value={3}>Balance</option>
                      </select>
                    </div>
                    <div className="single-item">
                      <select>
                        <option>All Filters</option>
                        <option value={1}>Filters 1</option>
                        <option value={2}>Filters 2</option>
                        <option value={3}>Filters 3</option>
                      </select>
                    </div>
                    <div className="single-item">
                      <button>Clear Filters</button>
                    </div>
                  </div> */}
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Description</th>
                          <th scope="col">Transaction ID</th>
                          <th scope="col">Type</th>
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions?.length > 0 && (
                          <>
                            {transactions?.map((transaction: any) => (
                              <tr
                                key={transaction._id}
                                data-bs-toggle="modal"
                                data-bs-target="#transactionsMod"
                                onClick={() =>
                                  setTransactionToView(transaction)
                                }
                              >
                                <th scope="row">
                                  <p>{transaction.description}</p>
                                </th>
                                <td>
                                  <p>#{transaction.transactionId}</p>
                                </td>
                                <td>
                                  <p>{transaction.type}</p>
                                </td>
                                <td>
                                  <p>
                                    {new Date(
                                      transaction.createdAt
                                    ).toLocaleDateString()}
                                  </p>
                                  <p className="mdr">
                                    {new Date(
                                      transaction.createdAt
                                    ).toLocaleTimeString()}
                                  </p>
                                </td>
                                <td>
                                  {transaction.status === "Cancelled" && (
                                    <p className="cancelled">Cancelled</p>
                                  )}
                                  {transaction.status === "Pending" && (
                                    <p className="pending">Pending</p>
                                  )}
                                  {transaction.status === "Completed" && (
                                    <p className="completed">Completed</p>
                                  )}
                                  {transaction.status === "Unconfirmed" && (
                                    <p className="cancelled">Unconfirmed</p>
                                  )}
                                </td>
                                <td>
                                  <p>${transaction.amount?.toLocaleString()}</p>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* <nav
                    aria-label="Page navigation"
                    className="d-flex justify-content-center mt-40"
                  >
                    <ul className="pagination justify-content-center align-items-center mb-40">
                      <li className="page-item">
                        <a
                          className="page-link previous"
                          href="#/"
                          aria-label="Previous"
                        >
                          <i className="fa-solid fa-angles-left" />
                        </a>
                      </li>
                      <li className="page-item">
                        <a
                          className="page-link previous"
                          href="#/"
                          aria-label="Previous"
                        >
                          <i className="fa-solid fa-angle-left" />
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#/">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link active" href="#/">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#/">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#/">
                          ...
                        </a>
                      </li>
                      <li className="page-item">
                        <a
                          className="page-link next"
                          href="#/"
                          aria-label="Next"
                        >
                          <i className="fa-solid fa-angle-right" />
                        </a>
                      </li>
                      <li className="page-item">
                        <a
                          className="page-link next"
                          href="#/"
                          aria-label="Next"
                        >
                          <i className="fa-solid fa-angles-right" />
                        </a>
                      </li>
                    </ul>
                  </nav> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="transactions-popup">
        <div className="container-fruid">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="modal fade"
                id="transactionsMod"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header justify-content-between">
                      <h5>Transaction Details</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa-solid fa-xmark" />
                      </button>
                    </div>
                    <div className="main-content">
                      <div className="row">
                        <div className="col-sm-5 text-center">
                          {transactionToView?.document && (
                            <div className="icon-area">
                              <a
                                href={transactionToView?.document}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={transactionToView?.document}
                                  alt="document"
                                />
                              </a>
                            </div>
                          )}
                          <div className="text-area">
                            <p>
                              {new Date(
                                transactionToView.createdAt
                              ).toLocaleString()}
                            </p>
                            <h3>
                              ${transactionToView?.amount?.toLocaleString()}
                            </h3>
                            {transactionToView.status === "Completed" && (
                              <p className="com">Completed</p>
                            )}
                            {transactionToView.status === "Pending" && (
                              <p className="com" style={{ color: "#f7a94a" }}>
                                Pending
                              </p>
                            )}
                            {transactionToView.status === "Cancelled" && (
                              <p className="com" style={{ color: "red" }}>
                                Cancelled
                              </p>
                            )}
                            {transactionToView.status === "Unconfirmed" && (
                              <p className="com" style={{ color: "red" }}>
                                Unconfirmed
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-7">
                          <div className="right-area">
                            <h6>Transaction Details</h6>
                            <ul className="payment-info">
                              {transactionToView.bankDetails && (
                                <>
                                  <li>
                                    <p>Account Name</p>
                                    <span className="mdr">
                                      {
                                        transactionToView.bankDetails
                                          ?.accountName
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <p>Account Number</p>
                                    <span className="mdr">
                                      {
                                        transactionToView.bankDetails
                                          ?.accountNumber
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <p>Bank Name</p>
                                    <span className="mdr">
                                      {transactionToView.bankDetails?.bankName}
                                    </span>
                                  </li>
                                  <li>
                                    <p>Routing Number</p>
                                    <span className="mdr">
                                      {
                                        transactionToView.bankDetails
                                          ?.routingNumber
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <p>Bank Address</p>
                                    <span className="mdr">
                                      {
                                        transactionToView.bankDetails
                                          ?.bankAddress
                                      }
                                    </span>
                                  </li>
                                </>
                              )}
                              <li>
                                <p>Description</p>
                                <span className="mdr">
                                  {transactionToView.description}
                                </span>
                              </li>
                              <li>
                                <p>Transaction ID:</p>
                                <span className="mdr">
                                  {transactionToView.transactionId}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
