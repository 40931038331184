import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, FormEventHandler } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../store/redux/userRedux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import avatar from "../assets/user.png";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../firebase";
import Preloader from "../components/Preloader";

const Account = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [removeActiveClass, setRemoveActiveClass] = useState(false);
  const [addBodyClass, setAddBodyClass] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<any>();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [occupation, setOccupation] = useState("");
  const [pageLoading, setPageLoading] = useState(true);

  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("paylio");

    const getUser = async () => {
      try {
        const { data } = await axios.get(
          "https://paylio-backend.onrender.com/api/user",
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        setFirstName(data.firstName);
        setMiddleName(data.middleName);
        setLastName(data.lastName);
        setEmail(data.email);
        setPhone(data.phone);
        setAddress(data.address);
        setOccupation(data.occupation);
        setTimeout(() => {
          setPageLoading(false);
        }, 300);
      } catch (error: any) {
        if (error.response) {
          if (error.response.data === "Token is not valid!") {
            localStorage.removeItem("paylio");
            dispatch(logoutUser());
          } else {
            localStorage.removeItem("paylio");
            dispatch(logoutUser());
          }
        }
      }
    };

    getUser();
  }, [dispatch]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 1399) {
      setRemoveActiveClass(true);
      setAddBodyClass(true);
    } else {
      setRemoveActiveClass(false);
      setAddBodyClass(false);
    }
  }, [windowWidth]);

  const toggleSidebar = () => {
    setRemoveActiveClass((prevState) => !prevState);
    setAddBodyClass((prevState) => !prevState);
  };

  const logout = () => {
    localStorage.removeItem("paylio");
    dispatch(logoutUser());
    navigate("/login");
  };

  const changePassword: FormEventHandler = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("paylio");

    if (newPassword.length < 8) {
      setPasswordWeak(true);
      return;
    } else {
      setPasswordWeak(false);
    }

    if (newPassword !== confirmPassword) {
      setNewPassword("");
      setConfirmPassword("");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    try {
      setLoading(true);

      await axios.put(
        `https://paylio-backend.onrender.com/api/auth/changepassword/${user?._id}`,
        { oldPassword, newPassword },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Password changed!");
      setTimeout(() => {
        navigate(0);
      }, 1800);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const editUser: FormEventHandler = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("paylio");

    if (image) {
      setLoading(true);

      const fileName = new Date().getTime() + image.name;
      const storage = getStorage(app);
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          setLoading(false);
          toast.error("Something went wrong");
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const edit = async () => {
              try {
                await axios.put(
                  `https://paylio-backend.onrender.com/api/user/${user?._id}`,
                  {
                    firstName,
                    middleName,
                    lastName,
                    email,
                    phone,
                    occupation,
                    address,
                    picture: downloadURL,
                  },
                  {
                    headers: { token: `Bearer ${token}` },
                  }
                );

                setLoading(false);
                toast.success("Changes saved");
                setTimeout(() => {
                  navigate(0);
                }, 1800);
              } catch (error) {
                setLoading(false);
                toast.error("Something went wrong");
              }
            };

            edit();
          });
        }
      );
    } else {
      try {
        setLoading(true);

        await axios.put(
          `https://paylio-backend.onrender.com/api/user/${user?._id}`,
          {
            firstName,
            middleName,
            lastName,
            email,
            phone,
            occupation,
            address,
          },
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        setLoading(false);
        toast.success("Changes saved");
        setTimeout(() => {
          navigate(0);
        }, 1800);
      } catch (error) {
        setLoading(false);
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      {pageLoading && <Preloader />}
      <header
        className={`${
          addBodyClass ? "header-section body-collapse" : "header-section"
        }`}
      >
        <div className="overlay">
          <div className="container-fruid">
            <div className="row d-flex header-area">
              <div className="navbar-area d-flex align-items-center justify-content-between">
                <div className="sidebar-icon" onClick={toggleSidebar}>
                  <img src="/assets/images/icon/menu.png" alt="icon" />
                </div>
                <form action="#" className="flex-fill">
                  <div className="form-group d-flex align-items-center">
                    <img src="/assets/images/icon/search.png" alt="icon" />
                    <input type="text" placeholder="Type to search..." />
                  </div>
                </form>
                <div className="dashboard-nav">
                  {/* <div className="single-item notifications-area">
                    <div className="notifications-btn">
                      <img
                        src="/assets/images/icon/bell.png"
                        className="bell-icon"
                        alt="icon"
                      />
                    </div>
                    <div className="main-area notifications-content">
                      <div className="head-area d-flex justify-content-between">
                        <h5>Notifications</h5>
                        <span className="mdr">4</span>
                      </div>
                      <ul>
                        <li>
                          <a href="#/" className="d-flex">
                            <div className="img-area">
                              <img
                                src="/assets/images/user-1.png"
                                className="max-un"
                                alt="dashboard"
                              />
                            </div>
                            <div className="text-area">
                              <p className="mdr">
                                You received a payment of <b>$134.00</b> from{" "}
                                <b>Anna Green</b>
                              </p>
                              <p className="mdr time-area">09.39AM</p>
                            </div>
                          </a>
                          <i className="fa-solid fa-caret-right" />
                        </li>
                        <li>
                          <a href="#/" className="d-flex">
                            <div className="img-area">
                              <img
                                src="assets/images/user-2.png"
                                className="max-un"
                                alt="dashboard"
                              />
                            </div>
                            <div className="text-area">
                              <p className="mdr">
                                <b>James Deed</b> requested a payment of
                                <b>£890.00</b>
                              </p>
                              <p className="mdr time-area">08.09AM</p>
                            </div>
                          </a>
                          <i className="fa-solid fa-caret-right" />
                        </li>
                        <li>
                          <a href="#/" className="d-flex">
                            <div className="img-area">
                              <img
                                src="assets/images/master-card.png"
                                className="max-un"
                                alt="dashboard"
                              />
                            </div>
                            <div className="text-area">
                              <p className="mdr">
                                Your new payment method has beed added
                                successfully
                              </p>
                              <p className="mdr time-area">09.39AM</p>
                            </div>
                          </a>
                          <i className="fa-solid fa-caret-right" />
                        </li>
                        <li>
                          <a href="#/" className="d-flex">
                            <div className="img-area">
                              <img
                                src="assets/images/user-3.png"
                                className="max-un"
                                alt="dashboard"
                              />
                            </div>
                            <div className="text-area">
                              <p className="mdr">
                                You received a payment of <b>$250.00</b> from
                                Goerge Michael
                              </p>
                              <p className="mdr time-area">Yesterday</p>
                            </div>
                          </a>
                          <i className="fa-solid fa-caret-right" />
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="single-item user-area">
                    <div className="profile-area d-flex align-items-center">
                      <span className="user-profile">
                        {user?.picture ? (
                          <img
                            src={user?.picture}
                            alt="User"
                            style={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src={avatar}
                            alt="User"
                            style={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </span>
                      <i className="fa-solid fa-sort-down" />
                    </div>
                    <div className="main-area user-content">
                      <div className="head-area d-flex align-items-center">
                        <div className="profile-img">
                          {user?.picture ? (
                            <img
                              src={user?.picture}
                              alt="User"
                              style={{
                                width: "32px",
                                height: "32px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <img
                              src={avatar}
                              alt="User"
                              style={{
                                width: "32px",
                                borderRadius: "50%",
                              }}
                            />
                          )}
                        </div>
                        <div className="profile-head">
                          <a href="#/">
                            <h5>
                              {user?.firstName} {user?.middleName}{" "}
                              {user?.lastName}
                            </h5>
                          </a>
                          <p className="wallet-id">
                            Account ID: {user.accountNumber}
                          </p>
                        </div>
                      </div>
                      <ul>
                        <li className="border-area">
                          <Link to="/account">
                            <i className="fas fa-cog" />
                            Settings
                          </Link>
                        </li>
                        <li>
                          <a href="#/" onClick={logout}>
                            <i className="fas fa-sign-out" />
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  removeActiveClass
                    ? "sidebar-wrapper"
                    : "sidebar-wrapper active"
                }`}
              >
                <div className="close-btn" onClick={toggleSidebar}>
                  <i className="fa-solid fa-xmark" />
                </div>
                <div className="sidebar-logo">
                  <Link to="/">
                    <img src="assets/images/logo.png" alt="logo" />
                  </Link>
                </div>
                <ul>
                  <li>
                    <Link to="/">
                      <img
                        src="assets/images/icon/dashboard.png"
                        alt="Dashboard"
                      />{" "}
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <img
                        src="assets/images/icon/transactions.png"
                        alt="Transactions"
                      />
                      <span>Transactions</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/deposit">
                      <img src="assets/images/icon/deposit.png" alt="Deposit" />{" "}
                      <span>Deposit Money</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/transfer">
                      <img
                        src="assets/images/icon/exchange.png"
                        alt="Exchange"
                      />{" "}
                      <span>Transfer</span>
                    </Link>
                  </li>
                  <li className="active">
                    <Link to="/account">
                      <img src="assets/images/icon/account.png" alt="Account" />{" "}
                      <span>Account</span>
                    </Link>
                  </li>
                  <li>
                    <a href="#/" onClick={logout}>
                      <img src="assets/images/icon/quit.png" alt="Quit" />{" "}
                      <span>Quit</span>
                    </a>
                  </li>
                </ul>
                <div className="pt-120">
                  <div className="invite-now">
                    <div className="img-area">
                      <img
                        src="assets/images/invite-now-illus.png"
                        alt="dashboard"
                      />
                    </div>
                    <p>Invite your friend and get $25</p>
                    <a href="#/" className="cmn-btn">
                      Invite Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="dashboard-section body-collapse account">
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content">
              <div className="row">
                <div className="col-xxl-3 col-xl-4 col-md-6">
                  <div className="owner-details">
                    <div className="profile-area">
                      <div className="profile-img">
                        {user?.picture ? (
                          <img
                            src={user?.picture}
                            alt="User"
                            style={{
                              width: "60px",
                              height: "60px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src={avatar}
                            alt="User"
                            style={{
                              width: "60px",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </div>
                      <div className="name-area">
                        <h6>
                          {user?.firstName} {user?.middleName} {user?.lastName}
                        </h6>
                        <p className="active-status">Active</p>
                      </div>
                    </div>
                    <div className="owner-info">
                      <ul>
                        <li>
                          <p>Account ID:</p>
                          <span className="mdr">{user.accountNumber}</span>
                        </li>
                        <li>
                          <p>Joined:</p>
                          <span className="mdr">
                            {new Date(user.createdAt).toLocaleDateString()}
                          </span>
                        </li>
                        {/* <li>
                          <p>Confirm status:</p>
                          <span className="mdr">80%</span>
                        </li> */}
                      </ul>
                    </div>
                    <div className="owner-action">
                      <a href="#/" onClick={logout}>
                        <img
                          src="assets/images/icon/logout.png"
                          alt="account"
                        />
                        Logout
                      </a>
                      {/* <a href="#/" className="delete">
                        <img
                          src="assets/images/icon/delete-2.png"
                          alt="account"
                        />
                        Delete Account
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className="col-xxl-9 col-xl-8">
                  <div className="tab-main">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="account-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#account"
                          type="button"
                          role="tab"
                          aria-controls="account"
                          aria-selected="true"
                        >
                          Account
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="security-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#security"
                          type="button"
                          role="tab"
                          aria-controls="security"
                          aria-selected="false"
                        >
                          Security
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="payment-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#payment"
                          type="button"
                          role="tab"
                          aria-controls="payment"
                          aria-selected="false"
                        >
                          Payment Methods
                        </button>
                      </li> */}
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="notification-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#notification"
                          type="button"
                          role="tab"
                          aria-controls="notification"
                          aria-selected="false"
                        >
                          Notification
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content mt-40">
                      <div
                        className="tab-pane fade show active"
                        id="account"
                        role="tabpanel"
                        aria-labelledby="account-tab"
                      >
                        <div className="upload-avatar">
                          <div className="avatar-left d-flex align-items-center">
                            <div className="profile-img">
                              {fileUploaded ? (
                                <img
                                  src={URL.createObjectURL(image)}
                                  alt="avatar"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <>
                                  {user?.picture ? (
                                    <img
                                      src={user?.picture}
                                      alt="User"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={avatar}
                                      alt="User"
                                      style={{
                                        width: "50px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </div>
                            <div className="instraction">
                              <h6>Your Avatar</h6>
                            </div>
                          </div>
                          <div className="avatar-right">
                            <div className="file-upload">
                              <div className="right-area">
                                <label className="file" htmlFor="avatar">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    id="avatar"
                                    onChange={(e: any) => {
                                      setFileUploaded(true);
                                      setImage(e.target.files[0]);
                                      setChangesMade(true);
                                    }}
                                  />
                                  <span className="file-custom" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <form onSubmit={editUser}>
                          <div className="row justify-content-center">
                            <div className="col-md-6">
                              <div className="single-input">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                  type="text"
                                  id="firstName"
                                  placeholder="Alfred"
                                  value={firstName}
                                  required
                                  onChange={(e) => {
                                    setFirstName(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="single-input">
                                <label htmlFor="middleName">Middle Name</label>
                                <input
                                  type="text"
                                  id="middleName"
                                  value={middleName}
                                  onChange={(e) => {
                                    setMiddleName(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="single-input">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                  type="text"
                                  id="lastName"
                                  value={lastName}
                                  required
                                  onChange={(e) => {
                                    setLastName(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="single-input">
                                <label htmlFor="email">Email</label>
                                <input
                                  type="email"
                                  id="email"
                                  value={email}
                                  required
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="single-input">
                                <label htmlFor="phone">Phone</label>
                                <div className="row input-status d-flex align-items-center">
                                  <div className="col-6">
                                    <input
                                      type="text"
                                      id="phone"
                                      value={phone}
                                      required
                                      onChange={(e) => {
                                        setPhone(e.target.value);
                                        setChangesMade(true);
                                      }}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <span className="confirm">
                                      <img
                                        src="assets/images/icon/confirm.png"
                                        alt="icon"
                                      />
                                      Account verified
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="single-input">
                                <label htmlFor="occupation">Occupation</label>
                                <input
                                  type="text"
                                  id="occupation"
                                  value={occupation}
                                  required
                                  onChange={(e) => {
                                    setOccupation(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                              <div className="single-input">
                                <label htmlFor="address">Address</label>
                                <input
                                  type="text"
                                  id="address"
                                  value={address}
                                  required
                                  onChange={(e) => {
                                    setAddress(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>

                            {changesMade && (
                              <div className="col-md-12">
                                <div className="btn-border">
                                  {loading ? (
                                    <button className="cmn-btn" type="button">
                                      Updating Account...
                                    </button>
                                  ) : (
                                    <button className="cmn-btn" type="submit">
                                      Update Account
                                    </button>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="security"
                        role="tabpanel"
                        aria-labelledby="security-tab"
                      >
                        {/* <div className="single-content authentication d-flex align-items-center justify-content-between">
                          <div className="left">
                            <h5>Two Factor Authentication</h5>
                            <p>
                              Two-Factor Authentication (2FA) can be used to
                              help protect your account
                            </p>
                          </div>
                          <div className="right">
                            <button>Enable</button>
                          </div>
                        </div> */}
                        <div className="change-pass mb-40">
                          <div className="row">
                            <div className="col-sm-6">
                              <h5>Change Password</h5>
                              <p>
                                You can always change your password for security
                                reasons or reset your password in case you
                                forgot it.
                              </p>
                              <a
                                href="#/"
                                onClick={() => {
                                  localStorage.removeItem("paylio");
                                  dispatch(logoutUser());
                                  navigate("/forgot-password");
                                }}
                              >
                                Forgot password?
                              </a>
                            </div>
                            <div className="col-sm-6">
                              <form onSubmit={changePassword}>
                                <div className="row justify-content-center">
                                  <div className="col-md-12">
                                    <div className="single-input">
                                      <label htmlFor="current-password">
                                        Current password
                                      </label>
                                      <input
                                        type="password"
                                        id="current-password"
                                        placeholder="Current password"
                                        value={oldPassword}
                                        required
                                        onChange={(e) => {
                                          setOldPassword(e.target.value);
                                          setPasswordError(false);
                                          setPasswordWeak(false);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="single-input">
                                      <label htmlFor="new-password">
                                        New password
                                      </label>
                                      <input
                                        type="password"
                                        id="new-password"
                                        placeholder="New password"
                                        value={newPassword}
                                        required
                                        onChange={(e) => {
                                          setNewPassword(e.target.value);
                                          setPasswordError(false);
                                          setPasswordWeak(false);
                                        }}
                                      />
                                      {passwordWeak && (
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "14px",
                                          }}
                                        >
                                          Password must be at least 8 characters
                                          long
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="single-input">
                                      <label htmlFor="confirm-password">
                                        Confirm New password
                                      </label>
                                      <input
                                        type="password"
                                        id="confirm-password"
                                        placeholder="Confirm New password"
                                        value={confirmPassword}
                                        required
                                        onChange={(e) => {
                                          setConfirmPassword(e.target.value);
                                          setPasswordError(false);
                                          setPasswordWeak(false);
                                        }}
                                      />
                                      {passwordError && (
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "14px",
                                          }}
                                        >
                                          Passwords do not match
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="btn-border w-100">
                                      {loading ? (
                                        <button
                                          className="cmn-btn w-100"
                                          type="button"
                                          disabled
                                        >
                                          Updating...
                                        </button>
                                      ) : (
                                        <button
                                          className="cmn-btn w-100"
                                          type="submit"
                                        >
                                          Update password
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        {/* <div className="single-content additional-security">
                          <h5>Additional security</h5>
                          <div className="single-setting">
                            <div className="left">
                              <h6>SMS recovery</h6>
                              <p>Number ending with 1234</p>
                            </div>
                            <div className="right">
                              <button className="active">Disable SMS</button>
                            </div>
                          </div>
                          <div className="single-setting">
                            <div className="left">
                              <h6>Autheticator App</h6>
                              <p>Google Authenticator</p>
                            </div>
                            <div className="right">
                              <button>Configure</button>
                            </div>
                          </div>
                          <div className="single-setting">
                            <div className="left">
                              <h6>SSL Certificate</h6>
                              <p>Secure Sockets Layer</p>
                            </div>
                            <div className="right">
                              <button>Configure</button>
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="single-content your-devices">
                          <div className="head-item d-flex align-items-center justify-content-between">
                            <h5>Your devices</h5>
                            <a href="#/">Log out on all devices</a>
                          </div>
                          <div className="single-setting">
                            <div className="left">
                              <div className="icon-area">
                                <img
                                  src="assets/images/icon/iphone.png"
                                  alt="icon"
                                />
                              </div>
                              <div className="text-area">
                                <h6>Iphone 13 Pro Max</h6>
                                <p>New York City · June 20 at 14:00</p>
                              </div>
                            </div>
                            <div className="right">
                              <button>Log out</button>
                            </div>
                          </div>
                          <div className="single-setting">
                            <div className="left">
                              <div className="icon-area">
                                <img
                                  src="assets/images/icon/ipad.png"
                                  alt="icon"
                                />
                              </div>
                              <div className="text-area">
                                <h6>iPad Pro</h6>
                                <p>New York City · June 20 at 14:00</p>
                              </div>
                            </div>
                            <div className="right">
                              <button>Log out</button>
                            </div>
                          </div>
                          <div className="single-setting">
                            <div className="left">
                              <div className="icon-area">
                                <img
                                  src="assets/images/icon/imac.png"
                                  alt="icon"
                                />
                              </div>
                              <div className="text-area">
                                <h6>iMac OSX</h6>
                                <p>New York City · June 20 at 14:00</p>
                              </div>
                            </div>
                            <div className="right">
                              <button>Log out</button>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div
                        className="tab-pane pb-120 fade"
                        id="payment"
                        role="tabpanel"
                        aria-labelledby="payment-tab"
                      >
                        <div className="card-area">
                          <h6>Linked Payment system</h6>
                          <div className="card-content d-flex flex-wrap">
                            {/* <div className="single-card">
                              <button
                                type="button"
                                className="reg w-100"
                                data-bs-toggle="modal"
                                data-bs-target="#transactionsMod"
                              >
                                <img
                                  src="assets/images/visa-card.png"
                                  alt="account"
                                  className="w-100"
                                />
                              </button>
                            </div> */}
                            <div className="single-card">
                              <button
                                type="button"
                                className="reg w-100"
                                data-bs-toggle="modal"
                                data-bs-target="#transactionsMod"
                              >
                                <img
                                  src="assets/images/paylio-card.png"
                                  alt="account"
                                  className="w-100"
                                />
                              </button>
                            </div>
                            <div className="single-card">
                              <button
                                type="button"
                                className="reg w-100"
                                data-bs-toggle="modal"
                                data-bs-target="#transactionsMod"
                              >
                                <img
                                  src="assets/images/paypal-card.png"
                                  alt="account"
                                  className="w-100"
                                />
                              </button>
                            </div>
                            <div className="single-card">
                              <button
                                type="button"
                                className="reg w-100"
                                data-bs-toggle="modal"
                                data-bs-target="#transactionsMod"
                              >
                                <img
                                  src="assets/images/blockchain-card.png"
                                  alt="account"
                                  className="w-100"
                                />
                              </button>
                            </div>
                            <div className="single-card">
                              <button
                                type="button"
                                className="reg w-100"
                                data-bs-toggle="modal"
                                data-bs-target="#addcardMod"
                              >
                                <img
                                  src="assets/images/add-new.png"
                                  alt="account"
                                  className="w-100"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="notification"
                        role="tabpanel"
                        aria-labelledby="notification-tab"
                      >
                        <div className="notification-single">
                          <h6>Announcements</h6>
                          <label className="switch">
                            <input type="checkbox" defaultChecked />
                            <span className="slider round" />
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Send payment</h6>
                          <label className="switch">
                            <input type="checkbox" defaultChecked />
                            <span className="slider round" />
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Receive a payment</h6>
                          <label className="switch">
                            <input type="checkbox" defaultChecked />
                            <span className="slider round" />
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Request payment</h6>
                          <label className="switch">
                            <input type="checkbox" defaultChecked />
                            <span className="slider round" />
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Have a problem with a payment</h6>
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round" />
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Special Offers</h6>
                          <label className="switch">
                            <input type="checkbox" defaultChecked />
                            <span className="slider round" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default Account;
