import { FormEventHandler, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Register = () => {
  const [dob, setDob] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [maritalStatus, setMaritalStatus] = useState("");
  const [noMaritalStatus, setNoMaritalStatus] = useState(false);
  const [gender, setGender] = useState("");
  const [noGender, setNoGender] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    occupation: "",
    address: "",
  });
  const navigate = useNavigate();

  function getRandom(length: number) {
    return Math.floor(
      Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
    );
  }

  const handleCheck = (e: any) => {
    const { value, checked } = e.target;

    if (checked) {
      setMaritalStatus(value);
      setNoMaritalStatus(false);
    }
  };

  const handleGenderCheck = (e: any) => {
    const { value, checked } = e.target;

    if (checked) {
      setGender(value);
      setNoGender(false);
    }
  };

  const inputChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const register: FormEventHandler = async (e) => {
    e.preventDefault();

    if (+formData.password.length < 8) {
      setPasswordWeak(true);
      return;
    } else {
      setPasswordWeak(false);
    }

    if (!maritalStatus) {
      setNoMaritalStatus(true);
      return;
    } else {
      setNoMaritalStatus(false);
    }

    if (formData.password !== formData.confirmPassword) {
      setFormData((prevState) => ({
        ...prevState,
        password: "",
        confirmPassword: "",
      }));
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    try {
      setLoading(true);

      const { data } = await axios.post(
        "https://paylio-backend.onrender.com/api/auth/register",
        {
          firstName: formData.firstName,
          middleName: formData.middleName,
          lastName: formData.lastName,
          email: formData.email?.toLowerCase(),
          password: formData.password,
          phone: formData.phone,
          occupation: formData.occupation,
          address: formData.address,
          dob,
          maritalStatus,
          gender,
          accountNumber: `9${getRandom(11)}`,
        }
      );

      if (data.message === "Verification email sent") {
        setLoading(false);
        navigate("/verify-account");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <section className="login-reg pb-120">
        <div className="overlay pt-120">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              {/* <div className="col-xl-6 order-xl-0 order-1">
                <div className="sec-img d-rtl">
                  <img
                    src="assets/images/login-reg-bg.png"
                    className="max-un"
                    alt="register"
                  />
                </div>
              </div> */}
              <div className="col-xl-6">
                <div className="section-text text-center">
                  <h2 className="title">Welcome to Paylio</h2>
                  <p className="dont-acc">
                    Already have an account? <Link to="/login">Login</Link>
                  </p>
                </div>
                <form onSubmit={register}>
                  <div className="row">
                    <div className="col-12">
                      <div className="single-input">
                        <label htmlFor="firstName">First Name</label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          placeholder="First Name"
                          required
                          value={formData.firstName}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />
                      </div>
                      <div className="single-input">
                        <label htmlFor="middleName">
                          Middle Name(Optional)
                        </label>
                        <input
                          type="text"
                          id="middleName"
                          name="middleName"
                          placeholder="Middle Name"
                          value={formData.middleName}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />
                      </div>
                      <div className="single-input">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                          type="text"
                          id="lastName"
                          name="lastName"
                          placeholder="Last Name"
                          required
                          value={formData.lastName}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />
                      </div>
                      <div className="single-input">
                        <label htmlFor="email">Email Address</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email Address"
                          required
                          value={formData.email}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />
                      </div>
                      <div className="single-input">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                          type="tel"
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          required
                          value={formData.phone}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />
                      </div>
                      <div className="single-input">
                        <label style={{ width: "100%" }} htmlFor="gender">
                          Gender
                        </label>
                        <input
                          className="form-check-input"
                          style={{
                            width: "20px",
                            height: "20px",
                            padding: "1px",
                            marginRight: "4px",
                            marginLeft: "8px",
                          }}
                          name="gender"
                          type="radio"
                          value="Male"
                          onChange={(e) => {
                            handleGenderCheck(e);
                          }}
                        />
                        <span className="form-check-label">Male</span>
                        <input
                          className="form-check-input"
                          style={{
                            width: "20px",
                            height: "20px",
                            padding: "1px",
                            marginRight: "4px",
                            marginLeft: "8px",
                          }}
                          name="gender"
                          type="radio"
                          value="Female"
                          onChange={(e) => {
                            handleGenderCheck(e);
                          }}
                        />
                        <span className="form-check-label">Female</span>
                        <input
                          className="form-check-input"
                          style={{
                            width: "20px",
                            height: "20px",
                            padding: "1px",
                            marginRight: "4px",
                            marginLeft: "8px",
                          }}
                          name="gender"
                          type="radio"
                          value="Other"
                          onChange={(e) => {
                            handleGenderCheck(e);
                          }}
                        />
                        <span className="form-check-label">Other</span>
                        {noGender && (
                          <p style={{ color: "red", fontSize: "15px" }}>
                            Please choose an option
                          </p>
                        )}
                      </div>
                      <div className="single-input">
                        <label htmlFor="occupation">Occupation</label>
                        <input
                          type="text"
                          id="occupation"
                          name="occupation"
                          placeholder="Occupation"
                          value={formData.occupation}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />
                      </div>
                      <div className="single-input">
                        <label
                          style={{ display: "block" }}
                          htmlFor="maritalStatus"
                        >
                          Marital Status
                        </label>
                        <input
                          className="form-check-input"
                          style={{
                            width: "20px",
                            height: "20px",
                            padding: "1px",
                            marginRight: "4px",
                          }}
                          name="maritalStatus"
                          type="radio"
                          value="Single"
                          onChange={(e) => {
                            handleCheck(e);
                          }}
                        />
                        <span className="form-check-label">Single</span>
                        <input
                          className="form-check-input"
                          style={{
                            width: "20px",
                            height: "20px",
                            padding: "1px",
                            marginRight: "4px",
                            marginLeft: "8px",
                          }}
                          name="maritalStatus"
                          type="radio"
                          value="Married"
                          onChange={(e) => {
                            handleCheck(e);
                          }}
                        />
                        <span className="form-check-label">Married</span>
                        <input
                          className="form-check-input"
                          style={{
                            width: "20px",
                            height: "20px",
                            padding: "1px",
                            marginRight: "4px",
                            marginLeft: "8px",
                          }}
                          name="maritalStatus"
                          type="radio"
                          value="Widowed"
                          onChange={(e) => {
                            handleCheck(e);
                          }}
                        />
                        <span className="form-check-label">Widowed</span>
                        <input
                          className="form-check-input"
                          style={{
                            width: "20px",
                            height: "20px",
                            padding: "1px",
                            marginRight: "4px",
                            marginLeft: "8px",
                          }}
                          name="maritalStatus"
                          type="radio"
                          value="Divorced"
                          onChange={(e) => {
                            handleCheck(e);
                          }}
                        />
                        <span className="form-check-label">Divorced</span>
                        {noMaritalStatus && (
                          <p style={{ color: "red", fontSize: "15px" }}>
                            Please choose an option
                          </p>
                        )}
                      </div>
                      <div className="single-input">
                        <label htmlFor="address">Address</label>
                        <input
                          type="text"
                          id="address"
                          name="address"
                          placeholder="Home or office address"
                          value={formData.address}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />
                      </div>
                      <div className="single-input">
                        <label style={{ display: "block" }} htmlFor="dob">
                          Date of Birth
                        </label>
                        <DatePicker
                          selected={dob}
                          onChange={(date: any) => setDob(date)}
                        />
                      </div>
                      <div className="single-input">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          required
                          placeholder="Enter a Password"
                          value={formData.password}
                          onChange={(e) => {
                            inputChange(e);
                            setPasswordError(false);
                            setPasswordWeak(false);
                          }}
                        />
                        {passwordWeak && (
                          <p style={{ color: "red", fontSize: "15px" }}>
                            Password should be at least 8 characters long
                          </p>
                        )}
                      </div>
                      <div className="single-input">
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          required
                          placeholder="Enter Password again"
                          value={formData.confirmPassword}
                          onChange={(e) => {
                            inputChange(e);
                            setPasswordError(false);
                            setPasswordWeak(false);
                          }}
                        />
                        {passwordError && (
                          <p style={{ color: "red", fontSize: "15px" }}>
                            Passwords do not match
                          </p>
                        )}
                      </div>
                      {loading ? (
                        <button type="button" className="cmn-btn w-100">
                          Submitting...
                        </button>
                      ) : (
                        <button type="submit" className="cmn-btn w-100">
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default Register;
