import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../store/redux/userRedux";
import avatar from "../assets/user.png";
import Preloader from "../components/Preloader";
import axios from "axios";

const Dashboard = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [removeActiveClass, setRemoveActiveClass] = useState(false);
  const [addBodyClass, setAddBodyClass] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [transactionToView, setTransactionToView] = useState<any>({});

  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("paylio");
    dispatch(logoutUser());
    navigate("/login");
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 1399) {
      setRemoveActiveClass(true);
      setAddBodyClass(true);
    } else {
      setRemoveActiveClass(false);
      setAddBodyClass(false);
    }
  }, [windowWidth]);

  const toggleSidebar = () => {
    setRemoveActiveClass((prevState) => !prevState);
    setAddBodyClass((prevState) => !prevState);
  };

  useEffect(() => {
    const token = localStorage.getItem("paylio");

    const getTransactions = async () => {
      const { data } = await axios.get(
        `https://paylio-backend.onrender.com/api/transaction/${user?._id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactions(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getTransactions();
  }, [user?._id]);

  return (
    <>
      {pageLoading && <Preloader />}
      <header
        className={`${
          addBodyClass ? "header-section body-collapse" : "header-section"
        }`}
      >
        <div className="overlay">
          <div className="container-fruid">
            <div className="row d-flex header-area">
              <div className="navbar-area d-flex align-items-center justify-content-between">
                <div className="sidebar-icon" onClick={toggleSidebar}>
                  <img src="/assets/images/icon/menu.png" alt="icon" />
                </div>
                <form action="#" className="flex-fill">
                  <div className="form-group d-flex align-items-center">
                    <img src="/assets/images/icon/search.png" alt="icon" />
                    <input type="text" placeholder="Type to search..." />
                  </div>
                </form>
                <div className="dashboard-nav">
                  <div className="single-item user-area">
                    <div className="profile-area d-flex align-items-center">
                      <span className="user-profile">
                        {user?.picture ? (
                          <img
                            src={user?.picture}
                            alt="User"
                            style={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src={avatar}
                            alt="User"
                            style={{
                              width: "32px",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </span>
                      <i className="fa-solid fa-sort-down" />
                    </div>
                    <div className="main-area user-content">
                      <div className="head-area d-flex align-items-center">
                        <div className="profile-img">
                          {user?.picture ? (
                            <img
                              src={user?.picture}
                              alt="User"
                              style={{
                                width: "60px",
                                height: "60px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          ) : (
                            <img
                              src={avatar}
                              alt="User"
                              style={{
                                width: "60px",
                                borderRadius: "50%",
                              }}
                            />
                          )}
                        </div>
                        <div className="profile-head">
                          <a href="#/">
                            <h5>
                              {user?.firstName} {user?.middleName}{" "}
                              {user?.lastName}
                            </h5>
                          </a>
                          <p className="wallet-id">
                            Account ID: {user.accountNumber}
                          </p>
                        </div>
                      </div>
                      <ul>
                        <li className="border-area">
                          <Link to="/account">
                            <i className="fas fa-cog" />
                            Settings
                          </Link>
                        </li>
                        <li>
                          <a href="#/" onClick={logout}>
                            <i className="fas fa-sign-out" />
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  removeActiveClass
                    ? "sidebar-wrapper"
                    : "sidebar-wrapper active"
                }`}
              >
                <div className="close-btn" onClick={toggleSidebar}>
                  <i className="fa-solid fa-xmark" />
                </div>
                <div className="sidebar-logo">
                  <Link to="/">
                    <img src="assets/images/logo.png" alt="logo" />
                  </Link>
                </div>
                <ul>
                  <li className="active">
                    <Link to="/">
                      <img
                        src="assets/images/icon/dashboard.png"
                        alt="Dashboard"
                      />{" "}
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <img
                        src="assets/images/icon/transactions.png"
                        alt="Transactions"
                      />
                      <span>Transactions</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/deposit">
                      <img src="assets/images/icon/deposit.png" alt="Deposit" />{" "}
                      <span>Deposit Money</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/transfer">
                      <img
                        src="assets/images/icon/exchange.png"
                        alt="Exchange"
                      />{" "}
                      <span>Transfer</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/account">
                      <img src="assets/images/icon/account.png" alt="Account" />{" "}
                      <span>Account</span>
                    </Link>
                  </li>
                  <li>
                    <a href="#/" onClick={logout}>
                      <img src="assets/images/icon/quit.png" alt="Quit" />{" "}
                      <span>Quit</span>
                    </a>
                  </li>
                </ul>
                <div className="pt-120">
                  <div className="invite-now">
                    <div className="img-area">
                      <img
                        src="assets/images/invite-now-illus.png"
                        alt="dashboard"
                      />
                    </div>
                    <p>Invite your friend and get $25</p>
                    <a href="#/" className="cmn-btn">
                      Invite Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="dashboard-section body-collapse">
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="row">
              <div className="col-xl-8 col-lg-7">
                <div className="section-content">
                  <div className="acc-details">
                    <div className="top-area">
                      <div className="left-side">
                        <h5>
                          Hi, {user?.firstName} {user?.middleName}{" "}
                          {user?.lastName}!
                        </h5>
                        <h2>${user?.balance?.toLocaleString()}</h2>
                      </div>
                    </div>
                    <div className="bottom-area">
                      <div className="left-side">
                        <Link to="/transfer" className="cmn-btn">
                          Transfer Money
                        </Link>
                        <Link to="/deposit" className="cmn-btn">
                          Deposit
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="transactions-area mt-40">
                    <div className="section-text">
                      <h5>Transactions</h5>
                    </div>
                    <div className="top-area d-flex align-items-center justify-content-between">
                      <ul className="nav nav-tabs" role="tablist">
                        {/* <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="latest-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#latest"
                            type="button"
                            role="tab"
                            aria-controls="latest"
                            aria-selected="true"
                          >
                            Latest
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="upcoming-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#upcoming"
                            type="button"
                            role="tab"
                            aria-controls="upcoming"
                            aria-selected="false"
                          >
                            Upcoming
                          </button>
                        </li> */}
                      </ul>
                      <div className="view-all d-flex align-items-center">
                        <Link to="/transactions">View All</Link>
                        <img
                          src="assets/images/icon/right-arrow.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="tab-content mt-40">
                      <div
                        className="tab-pane fade show active"
                        id="latest"
                        role="tabpanel"
                        aria-labelledby="latest-tab"
                      >
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Transaction ID</th>
                                <th scope="col">Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Amount</th>
                              </tr>
                            </thead>
                            {transactions?.length > 0 && (
                              <tbody>
                                {transactions
                                  ?.slice(0, 6)
                                  ?.map((transaction: any) => (
                                    <tr
                                      key={transaction._id}
                                      data-bs-toggle="modal"
                                      data-bs-target="#transactionsMod"
                                      onClick={() =>
                                        setTransactionToView(transaction)
                                      }
                                    >
                                      <th scope="row">
                                        <p>#{transaction.transactionId}</p>
                                      </th>
                                      <td>
                                        <p>
                                          {new Date(
                                            transaction.createdAt
                                          ).toLocaleDateString()}
                                        </p>
                                        <p className="mdr">
                                          {new Date(
                                            transaction.createdAt
                                          ).toLocaleTimeString()}
                                        </p>
                                      </td>
                                      <td>
                                        {transaction.status === "Cancelled" && (
                                          <p className="cancelled">Cancelled</p>
                                        )}
                                        {transaction.status === "Pending" && (
                                          <p className="pending">Pending</p>
                                        )}
                                        {transaction.status === "Completed" && (
                                          <p className="completed">Completed</p>
                                        )}
                                        {transaction.status ===
                                          "Unconfirmed" && (
                                          <p className="cancelled">
                                            Unconfirmed
                                          </p>
                                        )}
                                      </td>
                                      <td>
                                        <p>
                                          $
                                          {transaction.amount?.toLocaleString()}
                                        </p>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="upcoming"
                        role="tabpanel"
                        aria-labelledby="upcoming-tab"
                      >
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Transaction ID</th>
                                <th scope="col">Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Amount</th>
                              </tr>
                            </thead>
                            {transactions?.length > 0 && (
                              <tbody>
                                {transactions
                                  ?.slice(0, 6)
                                  ?.map((transaction: any) => (
                                    <tr
                                      key={transaction._id}
                                      data-bs-toggle="modal"
                                      data-bs-target="#transactionsMod"
                                      onClick={() =>
                                        setTransactionToView(transaction)
                                      }
                                    >
                                      <th scope="row">
                                        <p>#{transaction.transactionId}</p>
                                      </th>
                                      <td>
                                        <p>
                                          {new Date(
                                            transaction.createdAt
                                          ).toLocaleDateString()}
                                        </p>
                                        <p className="mdr">
                                          {new Date(
                                            transaction.createdAt
                                          ).toLocaleTimeString()}
                                        </p>
                                      </td>
                                      <td>
                                        {transaction.status === "Cancelled" && (
                                          <p className="cancelled">Cancelled</p>
                                        )}
                                        {transaction.status === "Pending" && (
                                          <p className="pending">Pending</p>
                                        )}
                                        {transaction.status === "Completed" && (
                                          <p className="completed">Completed</p>
                                        )}
                                        {transaction.status ===
                                          "Unconfirmed" && (
                                          <p className="cancelled">
                                            Unconfirmed
                                          </p>
                                        )}
                                      </td>
                                      <td>
                                        <p>
                                          $
                                          {transaction.amount?.toLocaleString()}
                                        </p>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5">
                <div className="side-items">
                  <div className="single-item">
                    <div className="section-text d-flex align-items-center justify-content-between">
                      <h6>Linked Payment system</h6>
                      {/* <div className="right-side">
                        <div className="dropdown-area">
                          <button
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src="assets/images/icon/option.png"
                              alt="icon"
                            />
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link className="dropdown-item" to="/account">
                                Update
                              </Link>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#/">
                                Virtual card
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="single-card">
                          <button
                            type="button"
                            className="reg w-100"
                            // data-bs-toggle="modal"
                            // data-bs-target="#cardMod"
                          >
                            <img
                              src="assets/images/paylio-card.png"
                              alt="dashboard"
                              className="w-100"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="single-card">
                          <button
                            type="button"
                            className="reg w-100"
                            // data-bs-toggle="modal"
                            // data-bs-target="#cardMod"
                          >
                            <img
                              src="assets/images/paypal-card.png"
                              alt="dashboard"
                              className="w-100"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="single-card">
                          <button
                            type="button"
                            className="reg w-100"
                            // data-bs-toggle="modal"
                            // data-bs-target="#addcardMod"
                          >
                            <img
                              src="assets/images/add-new.png"
                              alt="dashboard"
                              className="w-100"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="single-item">
                    <div className="section-text d-flex align-items-center justify-content-between">
                      <h6>Payment Analytics</h6>
                      <div className="select-box">
                        <select>
                          <option>Monthly</option>
                          <option value={1}>Jan</option>
                          <option value={2}>Feb</option>
                          <option value={3}>Mar</option>
                        </select>
                      </div>
                    </div>
                    <div id="chart" />
                  </div> */}
                  {/* <div className="single-item">
                    <div className="section-text d-flex align-items-center justify-content-between">
                      <h6>Recipients</h6>
                      <div className="view-all d-flex align-items-center">
                        <a href="#/">View All</a>
                        <img
                          src="assets/images/icon/right-arrow.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <ul className="recipients-item">
                      <li>
                        <p className="left d-flex align-items-center">
                          <span className="info">
                            <span>Phil King</span>
                            <span>08:00 AM — 19 August</span>
                          </span>
                        </p>
                        <p className="right">
                          <span> +$345</span>
                          <span>Payment</span>
                        </p>
                      </li>
                      <li>
                        <p className="left d-flex align-items-center">
                          <span className="info">
                            <span>Debra Wilson</span>
                            <span>08:00 AM — 19 August</span>
                          </span>
                        </p>
                        <p className="right">
                          <span className="loss">-$850</span>
                          <span>Refund</span>
                        </p>
                      </li>
                      <li>
                        <p className="left d-flex align-items-center">
                          <span className="info">
                            <span>Philip Henry</span>
                            <span>08:00 AM — 19 August</span>
                          </span>
                        </p>
                        <p className="right">
                          <span>+$2.050</span>
                          <span>Payment</span>
                        </p>
                      </li>
                      <li>
                        <p className="left d-flex align-items-center">
                          <span className="info">
                            <span>Erin Garcia</span>
                            <span>08:00 AM — 19 August</span>
                          </span>
                        </p>
                        <p className="right">
                          <span> +$900</span>
                          <span>Payment</span>
                        </p>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="card-popup">
        <div className="container-fruid">
          <div className="row">
            <div className="col-lg-6">
              <div className="modal fade" id="cardMod" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header justify-content-center">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa-solid fa-xmark" />
                      </button>
                    </div>
                    <div className="main-content">
                      <div className="top-area mb-40 mt-40 text-center">
                        <div className="card-area mb-30">
                          <img
                            src="assets/images/visa-card-2.png"
                            alt="dashboard"
                          />
                        </div>
                        <div className="text-area">
                          <h5>Paylio payment Card </h5>
                          <p>Linked: 01 Jun 2021</p>
                        </div>
                      </div>
                      <div className="tab-area d-flex align-items-center justify-content-between">
                        <ul className="nav nav-tabs mb-30" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button
                              className="btn-link"
                              id="cancel-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#cancel"
                              type="button"
                              role="tab"
                              aria-controls="cancel"
                              aria-selected="false"
                            >
                              <img
                                src="assets/images/icon/limit.png"
                                alt="icon"
                              />
                              Set transfer limit
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="d-none"
                              id="limit-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#limit"
                              type="button"
                              role="tab"
                              aria-controls="limit"
                              aria-selected="true"
                            />
                          </li>
                          <li>
                            <button>
                              <img
                                src="assets/images/icon/remove.png"
                                alt="icon"
                              />
                              Remove from Linked
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content mt-30">
                        <div
                          className="tab-pane fade show active"
                          id="limit"
                          role="tabpanel"
                          aria-labelledby="limit-tab"
                        >
                          <div className="bottom-area">
                            <p className="history">
                              Transaction History : <span>20</span>
                            </p>
                            <ul>
                              <li>
                                <p className="left">
                                  <span>03:00 PM</span>
                                  <span>17 Oct, 2020</span>
                                </p>
                                <p className="right">
                                  <span>$160.48</span>
                                  <span>Withdraw</span>
                                </p>
                              </li>
                              <li>
                                <p className="left">
                                  <span>01:09 PM</span>
                                  <span>15 Oct, 2021</span>
                                </p>
                                <p className="right">
                                  <span>$106.58</span>
                                  <span>Withdraw</span>
                                </p>
                              </li>
                              <li>
                                <p className="left">
                                  <span>04:00 PM</span>
                                  <span>12 Oct, 2020</span>
                                </p>
                                <p className="right">
                                  <span>$176.58</span>
                                  <span>Withdraw</span>
                                </p>
                              </li>
                              <li>
                                <p className="left">
                                  <span>06:00 PM</span>
                                  <span>25 Oct, 2020</span>
                                </p>
                                <p className="right">
                                  <span>$167.85</span>
                                  <span>Withdraw</span>
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="cancel"
                          role="tabpanel"
                          aria-labelledby="cancel-tab"
                        >
                          <div className="main-area">
                            <div className="transfer-area">
                              <p>
                                Set a transfer limit for paylio payment Card
                              </p>
                              <p className="mdr">Transfer Limit</p>
                            </div>
                            <form action="#">
                              <div className="input-area">
                                <input
                                  className="xxlr"
                                  placeholder="400.00"
                                  type="number"
                                />
                                <select>
                                  <option value={1}>USD</option>
                                  <option value={2}>USD</option>
                                  <option value={3}>USD</option>
                                </select>
                              </div>
                              <div className="bottom-area d-flex align-items-center justify-content-between">
                                <a href="#/" className="cmn-btn cancel">
                                  Cancel and Back
                                </a>
                                <a href="#/" className="cmn-btn">
                                  Confirm Transfer Limit
                                </a>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="add-card">
        <div className="container-fruid">
          <div className="row">
            <div className="col-lg-6">
              <div className="modal fade" id="addcardMod" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header justify-content-between">
                      <h6>Add Card</h6>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa-solid fa-xmark" />
                      </button>
                    </div>
                    <form action="#">
                      <div className="row justify-content-center">
                        <div className="col-md-12">
                          <div className="single-input">
                            <label htmlFor="cardNumber">Card Number</label>
                            <input
                              type="text"
                              id="cardNumber"
                              placeholder="5890 - 6858 - 6332 - 9843"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="single-input">
                            <label htmlFor="cardHolder">Card Holder</label>
                            <input
                              type="text"
                              id="cardHolder"
                              placeholder="Alfred Davis"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="single-input">
                            <label htmlFor="month">Month</label>
                            <input type="text" id="month" placeholder="12" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="single-input">
                            <label htmlFor="year">Year</label>
                            <input type="text" id="year" placeholder="2025" />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="btn-border w-100">
                            <button className="cmn-btn w-100">Add Card</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="transactions-popup">
        <div className="container-fruid">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="modal fade"
                id="transactionsMod"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header justify-content-between">
                      <h5>Transaction Details</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa-solid fa-xmark" />
                      </button>
                    </div>
                    <div className="main-content">
                      <div className="row">
                        <div className="col-sm-5 text-center">
                          {transactionToView?.document && (
                            <div className="icon-area">
                              <a
                                href={transactionToView?.document}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={transactionToView?.document}
                                  alt="document"
                                />
                              </a>
                            </div>
                          )}
                          <div className="text-area">
                            <p>
                              {new Date(
                                transactionToView.createdAt
                              ).toLocaleString()}
                            </p>
                            <h3>
                              ${transactionToView?.amount?.toLocaleString()}
                            </h3>
                            {transactionToView.status === "Completed" && (
                              <p className="com">Completed</p>
                            )}
                            {transactionToView.status === "Pending" && (
                              <p className="com" style={{ color: "#f7a94a" }}>
                                Pending
                              </p>
                            )}
                            {transactionToView.status === "Cancelled" && (
                              <p className="com" style={{ color: "red" }}>
                                Cancelled
                              </p>
                            )}
                            {transactionToView.status === "Unconfirmed" && (
                              <p className="com" style={{ color: "red" }}>
                                Unconfirmed
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-7">
                          <div className="right-area">
                            <h6>Transaction Details</h6>
                            <ul className="payment-info">
                              {transactionToView.bankDetails && (
                                <>
                                  <li>
                                    <p>Account Name</p>
                                    <span className="mdr">
                                      {
                                        transactionToView.bankDetails
                                          ?.accountName
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <p>Account Number</p>
                                    <span className="mdr">
                                      {
                                        transactionToView.bankDetails
                                          ?.accountNumber
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <p>Bank Name</p>
                                    <span className="mdr">
                                      {transactionToView.bankDetails?.bankName}
                                    </span>
                                  </li>
                                  <li>
                                    <p>Routing Number</p>
                                    <span className="mdr">
                                      {
                                        transactionToView.bankDetails
                                          ?.routingNumber
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <p>Bank Address</p>
                                    <span className="mdr">
                                      {
                                        transactionToView.bankDetails
                                          ?.bankAddress
                                      }
                                    </span>
                                  </li>
                                </>
                              )}
                              <li>
                                <p>Description</p>
                                <span className="mdr">
                                  {transactionToView.description}
                                </span>
                              </li>
                              <li>
                                <p>Transaction ID:</p>
                                <span className="mdr">
                                  {transactionToView.transactionId}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
