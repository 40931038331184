import { FormEventHandler, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const { resetToken } = useParams();

  const resetPassword: FormEventHandler = async (e) => {
    e.preventDefault();

    if (password.length < 8) {
      setPasswordWeak(true);
      return;
    } else {
      setPasswordWeak(false);
    }

    if (password !== confirmPassword) {
      setPassword("");
      setConfirmPassword("");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    try {
      setLoading(true);

      await axios.put(
        `https://paylio-backend.onrender.com/api/auth/passwordreset/${resetToken}`,
        { password }
      );

      setLoading(false);
      toast.success("Password reset successfully!");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <section className="login-reg">
        <div className="overlay pt-120">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-5">
                <div className="section-text text-center">
                  <h2 className="title">Set New Password</h2>
                </div>
                <form onSubmit={resetPassword}>
                  <div className="row">
                    <div className="col-12">
                      <div className="single-input">
                        <label htmlFor="password">New password</label>
                        <input
                          type="password"
                          id="password"
                          placeholder="Enter Password"
                          required
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordWeak(false);
                            setPasswordError(false);
                          }}
                        />
                        {passwordWeak && (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            Password should be at least 8 characters long
                          </p>
                        )}
                      </div>
                      <div className="single-input">
                        <label htmlFor="confirm-password">
                          Confirm password
                        </label>
                        <input
                          type="password"
                          id="confirm-password"
                          placeholder="Enter Password again"
                          required
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setPasswordWeak(false);
                            setPasswordError(false);
                          }}
                        />
                        {passwordError && (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            Passwords do not match
                          </p>
                        )}
                      </div>

                      {loading ? (
                        <button type="button" className="cmn-btn w-100">
                          Proceeding...
                        </button>
                      ) : (
                        <button type="submit" className="cmn-btn w-100">
                          Proceed
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default ResetPassword;
