import { useState, useEffect, FormEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Otp = () => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (otp?.length >= 6) {
      setFinished(true);
    } else {
      setFinished(false);
    }
  }, [otp?.length]);

  const verify: FormEventHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post("https://paylio-backend.onrender.com/api/auth/verify", {
        otp: +otp,
      });

      setLoading(false);
      toast.success("Account Verified!");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <section className="login-reg">
        <div className="overlay pt-120">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-5">
                <div className="section-text text-center">
                  <h2 className="title">Verify Account</h2>
                  <p className="dont-acc">
                    A verification code has been sent to your email address.
                    <br /> Please enter the code below.
                  </p>
                </div>
                <form onSubmit={verify}>
                  <div className="row">
                    <div className="col-12">
                      <div className="single-input">
                        <label htmlFor="otp" />
                        <input
                          type="text"
                          id="otp"
                          placeholder="123456"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </div>
                      {finished && (
                        <>
                          {loading ? (
                            <button
                              type="button"
                              className="cmn-btn w-100"
                              disabled
                            >
                              Confirming...
                            </button>
                          ) : (
                            <button type="submit" className="cmn-btn w-100">
                              Confirm
                            </button>
                          )}
                        </>
                      )}
                      <p style={{ marginTop: "24px", marginBottom: "12px" }}>
                        If you don't get an email from us within 5 minutes, one
                        of the following could have happened:
                      </p>
                      <ul className="row g-3 li_animate text-muted">
                        <li>- The email is in your spam/junk folder.</li>
                        <li>
                          - You entered an incorrect or invalid email address.
                        </li>
                        <li>
                          - We can't send emails to the email address provided.
                        </li>
                      </ul>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default Otp;
