import { useState, FormEventHandler } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const forgotPasswordHandler: FormEventHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const { data } = await axios.post(
        "https://paylio-backend.onrender.com/api/auth/forgotpassword",
        {
          email: email.toLowerCase(),
        }
      );

      if (data === "Email sent") {
        setLoading(false);
        setEmail("");
        toast.success("Email sent!");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <section className="login-reg">
        <div className="overlay pt-120">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-5">
                <div className="section-text text-center">
                  <h2 className="title">Reset Password</h2>
                  <p className="dont-acc">
                    Enter your email address and we'll send you an email on how
                    to reset your password
                  </p>
                </div>
                <form onSubmit={forgotPasswordHandler}>
                  <div className="row">
                    <div className="col-12">
                      <div className="single-input">
                        <label htmlFor="email">Your email address</label>
                        <input
                          type="email"
                          id="email"
                          placeholder="Enter Your Email address"
                          required
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>

                      {loading ? (
                        <button type="button" className="cmn-btn w-100">
                          Proceeding...
                        </button>
                      ) : (
                        <button type="submit" className="cmn-btn w-100">
                          Proceed
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default ForgotPassword;
