import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadingUser,
  noUser,
  loadUser,
  logoutUser,
} from "./store/redux/userRedux";
import axios from "axios";
import Dashboard from "./pages/Dashboard";
import Account from "./pages/Account";
import Transactions from "./pages/Transactions";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Otp from "./pages/Otp";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Transfer from "./pages/Transfer";
import Deposit from "./pages/Deposit";

const App = () => {
  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingUser());
    const token = localStorage.getItem("paylio");

    const getUser = async () => {
      try {
        const { data } = await axios.get(
          "https://paylio-backend.onrender.com/api/user",
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        dispatch(loadUser(data));
      } catch (error: any) {
        if (error.response) {
          if (error.response.data === "Token is not valid!") {
            localStorage.removeItem("paylio");
            dispatch(logoutUser());
          }
        }
      }
    };

    if (token) {
      getUser();
    } else {
      dispatch(noUser());
    }
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={user ? <Dashboard /> : <Login />} />
      <Route
        path="/account"
        element={user ? <Account /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/deposit"
        element={user ? <Deposit /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/forgot-password"
        element={!user ? <ForgotPassword /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/login"
        element={!user ? <Login /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/passwordreset/:resetToken"
        element={!user ? <ResetPassword /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/register"
        element={!user ? <Register /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/transfer"
        element={user ? <Transfer /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/transactions"
        element={user ? <Transactions /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/verify-account"
        element={!user ? <Otp /> : <Navigate replace to={"/"} />}
      />
    </Routes>
  );
};

export default App;
