import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDbPUNBVtN7Vbv2HAJVeyKae7A4zgtezns",
  authDomain: "paylio.firebaseapp.com",
  projectId: "paylio",
  storageBucket: "paylio.appspot.com",
  messagingSenderId: "730698348476",
  appId: "1:730698348476:web:acbc8b9cb245f7dc3a1db9",
};

const app = initializeApp(firebaseConfig);

export default app;
