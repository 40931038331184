import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, FormEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../store/redux/userRedux";
import avatar from "../assets/user.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Transfer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [removeActiveClass, setRemoveActiveClass] = useState(false);
  const [addBodyClass, setAddBodyClass] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [stepOneCompleted, setStepOneCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [transferCompleted, setTransferCompleted] = useState(false);
  const [otp, setOtp] = useState("");
  const [balanceError, setBalanceError] = useState(false);

  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("paylio");
    dispatch(logoutUser());
    navigate("/login");
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 1399) {
      setRemoveActiveClass(true);
      setAddBodyClass(true);
    } else {
      setRemoveActiveClass(false);
      setAddBodyClass(false);
    }
  }, [windowWidth]);

  const toggleSidebar = () => {
    setRemoveActiveClass((prevState) => !prevState);
    setAddBodyClass((prevState) => !prevState);
  };

  const sendOtp = async () => {
    setLoading(true);
    const token = localStorage.getItem("paylio");

    try {
      const { data } = await axios.post(
        `https://paylio-backend.onrender.com/api/transaction/initiate/${user?._id}`,
        {
          user,
          amount: +amount,
          description,
          email: user?.email,
          transactionId: new Date().getTime().toString(),
          bankDetails: {
            accountName,
            bankName,
            accountNumber,
            routingNumber,
            bankAddress,
          },
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      if (data.message === "Verification email sent") {
        setLoading(false);
        setOtpModalOpen(true);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const checkOtp = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("paylio");

    try {
      await axios.post(
        `https://paylio-backend.onrender.com/api/transaction/verify/${user?._id}`,
        { otp: +otp },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setOtpModalOpen(false);
      setTransferCompleted(true);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <header
        className={`${
          addBodyClass ? "header-section body-collapse" : "header-section"
        }`}
      >
        <div className="overlay">
          <div className="container-fruid">
            <div className="row d-flex header-area">
              <div className="navbar-area d-flex align-items-center justify-content-between">
                <div className="sidebar-icon" onClick={toggleSidebar}>
                  <img src="/assets/images/icon/menu.png" alt="icon" />
                </div>
                <form action="#" className="flex-fill">
                  <div className="form-group d-flex align-items-center">
                    <img src="/assets/images/icon/search.png" alt="icon" />
                    <input type="text" placeholder="Type to search..." />
                  </div>
                </form>
                <div className="dashboard-nav">
                  <div className="single-item user-area">
                    <div className="profile-area d-flex align-items-center">
                      <span className="user-profile">
                        {user?.picture ? (
                          <img
                            src={user?.picture}
                            alt="User"
                            style={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src={avatar}
                            alt="User"
                            style={{
                              width: "32px",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </span>
                      <i className="fa-solid fa-sort-down" />
                    </div>
                    <div className="main-area user-content">
                      <div className="head-area d-flex align-items-center">
                        <div className="profile-img">
                          {user?.picture ? (
                            <img
                              src={user?.picture}
                              alt="User"
                              style={{
                                width: "60px",
                                height: "60px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          ) : (
                            <img
                              src={avatar}
                              alt="User"
                              style={{
                                width: "60px",
                                borderRadius: "50%",
                              }}
                            />
                          )}
                        </div>
                        <div className="profile-head">
                          <a href="#/">
                            <h5>
                              {user?.firstName} {user?.middleName}{" "}
                              {user?.lastName}
                            </h5>
                          </a>
                          <p className="wallet-id">
                            Account ID: {user.accountNumber}
                          </p>
                        </div>
                      </div>
                      <ul>
                        <li className="border-area">
                          <Link to="/account">
                            <i className="fas fa-cog" />
                            Settings
                          </Link>
                        </li>
                        <li>
                          <a href="#/" onClick={logout}>
                            <i className="fas fa-sign-out" />
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  removeActiveClass
                    ? "sidebar-wrapper"
                    : "sidebar-wrapper active"
                }`}
              >
                <div className="close-btn" onClick={toggleSidebar}>
                  <i className="fa-solid fa-xmark" />
                </div>
                <div className="sidebar-logo">
                  <Link to="/">
                    <img src="assets/images/logo.png" alt="logo" />
                  </Link>
                </div>
                <ul>
                  <li>
                    <Link to="/">
                      <img
                        src="assets/images/icon/dashboard.png"
                        alt="Dashboard"
                      />{" "}
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <img
                        src="assets/images/icon/transactions.png"
                        alt="Transactions"
                      />
                      <span>Transactions</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/deposit">
                      <img src="assets/images/icon/deposit.png" alt="Deposit" />{" "}
                      <span>Deposit Money</span>
                    </Link>
                  </li>
                  <li className="active">
                    <Link to="/transfer">
                      <img
                        src="assets/images/icon/exchange.png"
                        alt="Exchange"
                      />{" "}
                      <span>Transfer</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/account">
                      <img src="assets/images/icon/account.png" alt="Account" />{" "}
                      <span>Account</span>
                    </Link>
                  </li>
                  <li>
                    <a href="#/" onClick={logout}>
                      <img src="assets/images/icon/quit.png" alt="Quit" />{" "}
                      <span>Quit</span>
                    </a>
                  </li>
                </ul>
                <div className="pt-120">
                  <div className="invite-now">
                    <div className="img-area">
                      <img
                        src="assets/images/invite-now-illus.png"
                        alt="dashboard"
                      />
                    </div>
                    <p>Invite your friend and get $25</p>
                    <a href="#/" className="cmn-btn">
                      Invite Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {!stepOneCompleted && (
        <section className="dashboard-section body-collapse pay step step-2">
          <div className="overlay pt-120">
            <div className="container-fruid">
              <div className="main-content">
                <div className="head-area d-flex align-items-center justify-content-between">
                  <h4>Money Transfer</h4>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (amount > user.balance) {
                      setBalanceError(true);
                      return;
                    } else {
                      setStepOneCompleted(true);
                    }
                  }}
                >
                  <div className="exchange-content">
                    <div className="send-banance">
                      <span className="mdr">You Send ($)</span>
                      <div className="input-area">
                        <input
                          className="xxlr"
                          placeholder="4000"
                          type="number"
                          pattern="[0-9]*"
                          value={amount}
                          required
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </div>
                      <p>
                        Available Balance:
                        <b>${user?.balance?.toLocaleString()}</b>
                      </p>
                      {balanceError && (
                        <p style={{ color: "red", fontSize: "15px" }}>
                          Insufficient available balance
                        </p>
                      )}
                    </div>
                  </div>
                  <div
                    className="row justify-content-center"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="col-md-6" style={{ marginBottom: "12px" }}>
                      <div className="single-input">
                        <label
                          htmlFor="accountName"
                          style={{ marginBottom: "5px" }}
                        >
                          Beneficiary Account Name
                        </label>
                        <input
                          type="text"
                          id="accountName"
                          placeholder="Account name"
                          required
                          value={accountName}
                          onChange={(e) => setAccountName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6" style={{ marginBottom: "12px" }}>
                      <div className="single-input">
                        <label
                          htmlFor="accountNumber"
                          style={{ marginBottom: "5px" }}
                        >
                          Beneficiary Account Number
                        </label>
                        <input
                          type="number"
                          pattern="[0-9]*"
                          id="accountNumber"
                          placeholder="Account number"
                          required
                          value={accountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6" style={{ marginBottom: "12px" }}>
                      <div className="single-input">
                        <label
                          htmlFor="bankName"
                          style={{ marginBottom: "5px" }}
                        >
                          Bank Name
                        </label>
                        <input
                          type="text"
                          id="bankName"
                          placeholder="Bank name"
                          required
                          value={bankName}
                          onChange={(e) => setBankName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6" style={{ marginBottom: "12px" }}>
                      <div className="single-input">
                        <label
                          htmlFor="routingNumber"
                          style={{ marginBottom: "5px" }}
                        >
                          Routing Number
                        </label>
                        <input
                          type="number"
                          pattern="[0-9]*"
                          id="routingNumber"
                          placeholder="Routing number"
                          required
                          value={routingNumber}
                          onChange={(e) => setRoutingNumber(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        className="single-input"
                        style={{ marginBottom: "12px" }}
                      >
                        <label
                          htmlFor="bankAddress"
                          style={{ marginBottom: "5px" }}
                        >
                          Bank Address
                        </label>
                        <input
                          type="text"
                          id="bankAddress"
                          placeholder="Bank address"
                          required
                          value={bankAddress}
                          onChange={(e) => setBankAddress(e.target.value)}
                        />
                      </div>
                      <div
                        className="single-input"
                        style={{ marginBottom: "12px" }}
                      >
                        <label
                          htmlFor="description"
                          style={{ marginBottom: "5px" }}
                        >
                          Description
                        </label>
                        <textarea
                          rows={1}
                          id="description"
                          value={description}
                          placeholder="Description/Narration"
                          required
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="footer-area mt-40">
                    <button
                      className="w-100 active text-center"
                      style={{
                        padding: "12px",
                        background: "#5732c6",
                        color: "white",
                        borderRadius: "20px",
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
      {stepOneCompleted && (
        <section className="dashboard-section body-collapse pay step step-2 step-3">
          <div className="overlay pt-120">
            <div className="container-fruid">
              <div className="main-content">
                <div className="head-area d-flex align-items-center justify-content-between">
                  <h4>Money Transfer</h4>
                  <div className="icon-area"></div>
                </div>
                <div className="choose-recipient">
                  <div className="step-area">
                    <h5>Confirm Your Transfer</h5>
                  </div>
                </div>
                <div className="payment-details">
                  <div className="top-area">
                    <h6>Payment Details</h6>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <ul className="details-list">
                        <li>
                          <span>Amount:</span>
                          <b>${amount}</b>
                        </li>
                        <li>
                          <span>Account Name:</span>
                          <b>{accountName}</b>
                        </li>
                        <li>
                          <span>Account Number:</span>
                          <b>{accountNumber}</b>
                        </li>
                        <li>
                          <span>Bank Name:</span>
                          <b>{bankName}</b>
                        </li>
                        <li>
                          <span>Routing Number:</span>
                          <b>{routingNumber}</b>
                        </li>
                        <li>
                          <span>Bank Address:</span>
                          <b>{bankAddress}</b>
                        </li>
                        <li>
                          <span>Description:</span>
                          <b>{description}</b>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    sendOtp();
                  }}
                >
                  <div className="checkbox">
                    <input type="checkbox" id="confirm" required />
                    <label htmlFor="confirm" style={{ marginLeft: "4px" }}>
                      I confirm the payment details above
                    </label>
                  </div>
                  <div className="footer-area mt-40">
                    {loading ? (
                      <button
                        type="button"
                        disabled
                        className="transferMod"
                        style={{
                          padding: "12px 35px",
                          background: "#5732c6",
                          color: "white",
                          borderRadius: "20px",
                        }}
                      >
                        Confirming...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="transferMod active"
                        style={{
                          padding: "12px 35px",
                          background: "#5732c6",
                          color: "white",
                          borderRadius: "20px",
                        }}
                      >
                        Confirm
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
      {(transferCompleted || otpModalOpen) && (
        <>
          <div className="transfer-popup">
            <div className="container-fruid">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="modal fade show"
                    id="transferMod"
                    aria-hidden="true"
                    style={{ display: "block" }}
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <ul className="nav nav-tabs d-none" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            className={`${
                              otpModalOpen ? "nav-link active" : "nav-link"
                            }`}
                            id="transfer-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#transfer"
                            type="button"
                            role="tab"
                            aria-controls="transfer"
                            aria-selected="true"
                          >
                            transfer
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={`${
                              transferCompleted ? "nav-link active" : "nav-link"
                            }`}
                            id="success-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#success"
                            type="button"
                            role="tab"
                            aria-controls="success"
                            aria-selected="false"
                          >
                            Confirm
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className={`${
                            otpModalOpen
                              ? "tab-pane fade show active"
                              : "tab-pane fade"
                          }`}
                          id="transfer"
                          role="tabpanel"
                          aria-labelledby="transfer-tab"
                        >
                          <div className="modal-content">
                            <div className="modal-header mb-60 justify-content-between">
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setOtpModalOpen(false)}
                              >
                                <i className="fa-solid fa-xmark" />
                              </button>
                            </div>
                            <div className="main-content">
                              <h4>Confirm Transfer!</h4>
                              <p>
                                We have sent a verification code to your email.
                                Please enter verification code below to confirm
                                transfer.
                              </p>
                              <form onSubmit={checkOtp}>
                                <div className="userInput">
                                  <input
                                    type="text"
                                    placeholder="123456"
                                    required
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                  />
                                </div>
                                {loading ? (
                                  <button
                                    className="mt-60 confirm"
                                    type="button"
                                    disabled
                                  >
                                    Confirming...
                                  </button>
                                ) : (
                                  <button
                                    className="mt-60 confirm"
                                    type="submit"
                                  >
                                    Confirm
                                  </button>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${
                            transferCompleted
                              ? "tab-pane fade active show"
                              : "tab-pane fade"
                          }`}
                          id="success"
                          role="tabpanel"
                          aria-labelledby="success-tab"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <button
                                type="button"
                                className="btn-close d-block"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{ background: "transparent" }}
                                onClick={() => navigate("/transactions")}
                              >
                                <i
                                  className="fa-solid fa-xmark"
                                  style={{ color: "white" }}
                                />
                              </button>
                            </div>
                            <div className="main-content text-center pt-120 pb-120">
                              <img
                                src="assets/images/icon/success.png"
                                alt="icon"
                              />
                              <h3>Success</h3>
                              <p>
                                Your transfer of ${amount} is being reviewed.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </>
      )}

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default Transfer;
