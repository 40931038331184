import { useState, FormEventHandler } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../store/redux/userRedux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [input, setInput] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginHandler: FormEventHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      if (type === "email") {
        const { data } = await axios.post(
          "https://paylio-backend.onrender.com/api/auth/login",
          {
            email: input?.toLowerCase(),
            password,
          }
        );

        setLoading(false);
        dispatch(loginUser(data));
        localStorage.setItem("paylio", data.token);
        navigate("/");
      } else {
        const { data } = await axios.post(
          "https://paylio-backend.onrender.com/api/auth/login",
          {
            accountNumber: input,
            password,
          }
        );

        setLoading(false);
        dispatch(loginUser(data));
        localStorage.setItem("paylio", data.token);
        navigate("/");
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        if (error.response.data.error === "Account disabled") {
          toast.error("Account disabled. Please contact support.");
        } else {
          toast.error(error.response.data.error);
        }
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <section className="login-reg">
        <div className="overlay pt-120">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-5">
                <div className="section-text text-center">
                  <h2 className="title">Log in to Continue</h2>
                  <p className="dont-acc">
                    Don’t have an account? <Link to="/register">Sign up</Link>
                  </p>
                </div>
                <form onSubmit={loginHandler}>
                  <div className="row">
                    <div className="col-12">
                      <div className="single-input">
                        <label htmlFor="email">
                          Your email or account number
                        </label>
                        <input
                          type="text"
                          id="email"
                          placeholder="Enter Your Email or account number"
                          required
                          value={input}
                          onChange={(e) => {
                            setInput(e.target.value);
                            const value = e.target.value;
                            if (!isNaN(+value)) {
                              setType("accountNumber");
                            } else {
                              setType("email");
                            }
                          }}
                        />
                      </div>
                      <div className="single-input">
                        <label htmlFor="password">Your Password</label>
                        <input
                          type="password"
                          id="password"
                          placeholder="Enter Your Password"
                          required
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </div>
                      {loading ? (
                        <button type="button" className="cmn-btn w-100">
                          Logging in...
                        </button>
                      ) : (
                        <button type="submit" className="cmn-btn w-100">
                          Login
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                <div className="forgot-pass mt-30 text-center">
                  <Link to="/forgot-password">Forgot Password</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default Login;
