import { useState, useEffect, FormEventHandler } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../store/redux/userRedux";
import avatar from "../assets/user.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../firebase";

const Deposit = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [removeActiveClass, setRemoveActiveClass] = useState(false);
  const [addBodyClass, setAddBodyClass] = useState(false);
  const [depositOption, setDepositOption] = useState("");
  const [stageOneCompleted, setStageOneCompleted] = useState(false);
  const [stageTwoCompleted, setStageTwoCompleted] = useState(false);
  const [amount, setAmount] = useState("");
  const [amountFieldEmpty, setAmountFieldEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [depositCompleted, setDepositCompleted] = useState(false);
  const [image, setImage] = useState<any>();
  const [fileUploaded, setFileUploaded] = useState(false);

  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("paylio");
    dispatch(logoutUser());
    navigate("/login");
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 1399) {
      setRemoveActiveClass(true);
      setAddBodyClass(true);
    } else {
      setRemoveActiveClass(false);
      setAddBodyClass(false);
    }
  }, [windowWidth]);

  const toggleSidebar = () => {
    setRemoveActiveClass((prevState) => !prevState);
    setAddBodyClass((prevState) => !prevState);
  };

  const deposit: FormEventHandler = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("paylio");

    if (image) {
      setLoading(true);

      const fileName = new Date().getTime() + image.name;
      const storage = getStorage(app);
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          setLoading(false);
          toast.error("Something went wrong");
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const deposit = async () => {
              try {
                await axios.post(
                  `https://paylio-backend.onrender.com/api/transaction/deposit/${user?._id}`,
                  {
                    depositOption,
                    amount,
                    user,
                    description,
                    transactionId: new Date().getTime().toString(),
                    document: downloadURL,
                  },
                  {
                    headers: { token: `Bearer ${token}` },
                  }
                );

                setLoading(false);
                setDepositCompleted(true);
              } catch (error) {
                setLoading(false);
                toast.error("Something went wrong");
              }
            };

            deposit();
          });
        }
      );
    } else {
      try {
        setLoading(true);

        await axios.post(
          `https://paylio-backend.onrender.com/api/transaction/deposit/${user?._id}`,
          {
            depositOption,
            amount,
            user,
            description,
            transactionId: new Date().getTime().toString(),
          },
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        setLoading(false);
        setDepositCompleted(true);
      } catch (error) {
        setLoading(false);
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <header
        className={`${
          addBodyClass ? "header-section body-collapse" : "header-section"
        }`}
      >
        <div className="overlay">
          <div className="container-fruid">
            <div className="row d-flex header-area">
              <div className="navbar-area d-flex align-items-center justify-content-between">
                <div className="sidebar-icon" onClick={toggleSidebar}>
                  <img src="/assets/images/icon/menu.png" alt="icon" />
                </div>
                <form action="#" className="flex-fill">
                  <div className="form-group d-flex align-items-center">
                    <img src="/assets/images/icon/search.png" alt="icon" />
                    <input type="text" placeholder="Type to search..." />
                  </div>
                </form>
                <div className="dashboard-nav">
                  <div className="single-item user-area">
                    <div className="profile-area d-flex align-items-center">
                      <span className="user-profile">
                        {user?.picture ? (
                          <img
                            src={user?.picture}
                            alt="User"
                            style={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src={avatar}
                            alt="User"
                            style={{
                              width: "32px",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </span>
                      <i className="fa-solid fa-sort-down" />
                    </div>
                    <div className="main-area user-content">
                      <div className="head-area d-flex align-items-center">
                        <div className="profile-img">
                          {user?.picture ? (
                            <img
                              src={user?.picture}
                              alt="User"
                              style={{
                                width: "60px",
                                height: "60px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          ) : (
                            <img
                              src={avatar}
                              alt="User"
                              style={{
                                width: "60px",
                                borderRadius: "50%",
                              }}
                            />
                          )}
                        </div>
                        <div className="profile-head">
                          <a href="#/">
                            <h5>
                              {user?.firstName} {user?.middleName}{" "}
                              {user?.lastName}
                            </h5>
                          </a>
                          <p className="wallet-id">
                            Account ID: {user.accountNumber}
                          </p>
                        </div>
                      </div>
                      <ul>
                        <li className="border-area">
                          <Link to="/account">
                            <i className="fas fa-cog" />
                            Settings
                          </Link>
                        </li>
                        <li>
                          <a href="#/" onClick={logout}>
                            <i className="fas fa-sign-out" />
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  removeActiveClass
                    ? "sidebar-wrapper"
                    : "sidebar-wrapper active"
                }`}
              >
                <div className="close-btn" onClick={toggleSidebar}>
                  <i className="fa-solid fa-xmark" />
                </div>
                <div className="sidebar-logo">
                  <Link to="/">
                    <img src="assets/images/logo.png" alt="logo" />
                  </Link>
                </div>
                <ul>
                  <li>
                    <Link to="/">
                      <img
                        src="assets/images/icon/dashboard.png"
                        alt="Dashboard"
                      />{" "}
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <img
                        src="assets/images/icon/transactions.png"
                        alt="Transactions"
                      />
                      <span>Transactions</span>
                    </Link>
                  </li>
                  <li className="active">
                    <Link to="/deposit">
                      <img src="assets/images/icon/deposit.png" alt="Deposit" />{" "}
                      <span>Deposit Money</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/transfer">
                      <img
                        src="assets/images/icon/exchange.png"
                        alt="Exchange"
                      />{" "}
                      <span>Transfer</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/account">
                      <img src="assets/images/icon/account.png" alt="Account" />{" "}
                      <span>Account</span>
                    </Link>
                  </li>
                  <li>
                    <a href="#/" onClick={logout}>
                      <img src="assets/images/icon/quit.png" alt="Quit" />{" "}
                      <span>Quit</span>
                    </a>
                  </li>
                </ul>
                <div className="pt-120">
                  <div className="invite-now">
                    <div className="img-area">
                      <img
                        src="assets/images/invite-now-illus.png"
                        alt="dashboard"
                      />
                    </div>
                    <p>Invite your friend and get $25</p>
                    <a href="#/" className="cmn-btn">
                      Invite Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {!stageOneCompleted && !depositCompleted && (
        <section className="dashboard-section body-collapse pay step crypto deposit-money">
          <div className="overlay pt-120">
            <div className="container-fruid">
              <div className="main-content">
                <div className="head-area d-flex align-items-center justify-content-between">
                  <h4>Deposit Money</h4>
                </div>
                <div className="row justify-content-between pb-120">
                  <div className="col-xl-3 col-lg-4 col-md-5">
                    <div className="left-area">
                      <ul>
                        <li>
                          <a href="#/" className="single-link active">
                            Choose Payment Method
                          </a>
                        </li>
                        <li>
                          <a href="#/" className="single-link two">
                            Enter details
                          </a>
                        </li>
                        <li>
                          <a href="#/" className="single-link last">
                            Confirm Order
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-9 col-lg-8 col-md-7">
                    <div className="table-area">
                      <div className="head-area">
                        <h4>Payment Method</h4>
                      </div>
                      <div className="row pb-120">
                        <div className="col-xxl-6 col-xl-6 col-md-6">
                          <a
                            href="#/"
                            className="single-item"
                            onClick={() => {
                              setDepositOption("Cash");
                              setStageOneCompleted(true);
                            }}
                          >
                            <div className="icon-area">
                              <img
                                src="assets/images/icon/paylio-account-icon.png"
                                alt="icon"
                              />
                            </div>
                            <p className="mdr">Cash Deposit</p>
                            <span className="mdr">Pay for free</span>
                          </a>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-6">
                          <a
                            href="#/"
                            className="single-item"
                            onClick={() => {
                              setDepositOption("Cheque");
                              setStageOneCompleted(true);
                            }}
                          >
                            <div className="icon-area">
                              <img
                                src="assets/images/icon/bank-account-icon.png"
                                alt="icon"
                              />
                            </div>
                            <p className="mdr">Cheque Deposit</p>
                            <span className="mdr">Confirm cheque details</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {stageOneCompleted && !depositCompleted && !stageTwoCompleted && (
        <section className="dashboard-section body-collapse pay step crypto deposit-money">
          <div className="overlay pt-120">
            <div className="container-fruid">
              <div className="main-content">
                <div className="head-area d-flex align-items-center justify-content-between">
                  <h4>Deposit Money</h4>
                </div>
                <div className="row justify-content-between pb-120">
                  <div className="col-xl-3 col-lg-4 col-md-5">
                    <div className="left-area">
                      <ul>
                        <li>
                          <a href="#/" className="single-link active">
                            Choose Payment Method
                          </a>
                        </li>
                        <li>
                          <a href="#/" className="single-link active">
                            Enter details
                          </a>
                        </li>
                        <li>
                          <a href="#/" className="single-link last">
                            Confirm Order
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="col-xl-8 col-lg-8 col-md-7 dashboard-section pay step step-2 step-3 receive-3"
                    style={{ marginLeft: "0" }}
                  >
                    <div className="table-area">
                      <form action="#">
                        <div className="send-banance">
                          <span className="mdr">How much you want to add?</span>
                          <div className="input-area">
                            <input
                              className="xxlr"
                              placeholder="10,000"
                              type="number"
                              value={amount}
                              onChange={(e) => {
                                setAmount(e.target.value);
                                setAmountFieldEmpty(false);
                              }}
                            />
                          </div>
                          {amountFieldEmpty && (
                            <p style={{ color: "red", fontSize: "15px" }}>
                              This field is required
                            </p>
                          )}
                          <p>
                            Available Balance
                            <b>${user?.balance?.toLocaleString()}</b>
                          </p>
                          <span className="mdr" style={{ marginTop: "24px" }}>
                            Description/Narration
                          </span>
                          <div className="input-area">
                            <textarea
                              className="xxlr"
                              placeholder="Description/Narration"
                              value={description}
                              onChange={(e) => {
                                setDescription(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </form>
                      <div className="attach-documents">
                        <div className="top-area">
                          <h6>Attach document</h6>
                          <p>
                            Attact a supporting document (like an invoice,
                            cheque or work contact) to your payment request.
                          </p>
                          <div className="file-upload">
                            <div className="right-area">
                              <label className="file">
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e: any) => {
                                    setFileUploaded(true);
                                    setImage(e.target.files[0]);
                                  }}
                                />
                                {fileUploaded ? (
                                  <>
                                    <span className="file-custom" />
                                    <span className="file-custom2">
                                      FILE UPLOADED!
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span className="file-custom" />
                                    <span className="file-custom2" />
                                  </>
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="footer-area mt-40">
                      <a href="#/" onClick={() => setStageOneCompleted(false)}>
                        Previous Step
                      </a>
                      <a
                        href="#/"
                        className="active"
                        onClick={() => {
                          if (+amount <= 0) {
                            setAmountFieldEmpty(true);
                            return;
                          } else {
                            setAmountFieldEmpty(false);
                          }
                          setStageTwoCompleted(true);
                        }}
                      >
                        Next
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {stageOneCompleted && stageTwoCompleted && !depositCompleted && (
        <section className="dashboard-section body-collapse pay step step-3 crypto deposit-money">
          <div className="overlay pt-120">
            <div className="container-fruid">
              <div className="main-content">
                <div className="head-area d-flex align-items-center justify-content-between">
                  <h4>Deposit Money</h4>
                </div>
                <div className="row justify-content-between pb-120">
                  <div className="col-xl-3 col-lg-4">
                    <div className="left-area">
                      <ul>
                        <li>
                          <a href="#/" className="single-link active">
                            Choose Payment Method
                          </a>
                        </li>
                        <li>
                          <a href="#/" className="single-link active">
                            Enter details
                          </a>
                        </li>
                        <li>
                          <a href="#/" className="single-link active last">
                            Confirm Order
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <form onSubmit={deposit}>
                      <div className="payment-details">
                        <div className="top-area">
                          <h6>Confirm transaction</h6>
                        </div>
                        <div className="row">
                          <div className="col-xxl-8 col-xl-9 col-lg-12">
                            <ul className="details-list">
                              <li>
                                <span>Payment Method</span>
                                <b>{depositOption}</b>
                              </li>
                              <li>
                                <span>You will receive</span>
                                <b>${+amount}</b>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="checkbox-area mt-40 d-flex align-items-center justify-content-center">
                        <input
                          type="checkbox"
                          id="confirm"
                          name="confirm"
                          required
                        />
                        <label htmlFor="confirm">
                          I confirm the details above.
                        </label>
                      </div>
                      <div className="footer-area mt-40">
                        <a
                          href="#/"
                          onClick={() => setStageTwoCompleted(false)}
                        >
                          Previous Step
                        </a>
                        {loading ? (
                          <button
                            type="button"
                            disabled
                            className="transferMod"
                            style={{
                              padding: "12px 35px",
                              background: "#5732c6",
                              color: "white",
                              borderRadius: "20px",
                            }}
                          >
                            Confirming...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="active"
                            style={{
                              padding: "12px 35px",
                              background: "#5732c6",
                              color: "white",
                              borderRadius: "20px",
                            }}
                          >
                            Confirm
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {depositCompleted && (
        <section className="dashboard-section body-collapse pay step step-2 step-3 receive-3">
          <div className="overlay pt-120">
            <div className="container-fruid">
              <div className="main-content">
                <div className="head-area d-flex align-items-center justify-content-between">
                  <h4>Deposit Money</h4>
                </div>
                <div className="request-submitted pb-120 text-center">
                  <div className="top-area">
                    <div className="icon-area">
                      <img
                        src="assets/images/icon/submitted-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h3>Submitted</h3>
                    <p>
                      Your deposit request was submitted successfully and will
                      be reviewed shortly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default Deposit;
